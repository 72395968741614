import React from "react";

export const SearchPage = () => {
  return (
    <div className="w-full flex flex-col items-center">
      <div className="mt-4">
        <p className="font-bold text-lg">검색 기능은 준비 중입니다 :)</p>
      </div>
    </div>
  );
};
