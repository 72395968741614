import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/skyon_logo.png";
import textLogo from "../assets/images/skyon_logo_text.jpg";

export const Footer = () => {
  return (
    <>
      <div className="w-screen md:flex flex-col items-center justify-center h-60 drop-shadow-[0_-2px_10px_#0001] bg-white">
        <div className="grid grid-cols-2 text-center my-5">
          <p>
            <a
              href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
              className="bold"
            >
              이용약관
            </a>
          </p>
          <p>
            <a href="https://www.semomun.com/terms" className="bold">
              개인정보 처리방침
            </a>
          </p>
        </div>
        <div className="text-sm mt-5">
          <div className="text-center">
            <p>상호명: (주)스펙업애드 대표자:나승균</p>
            <p>서울특별시 강남구 역삼로3길 17, 7층(역삼동, 혜진빌딩)</p>
            <p>사업자등록번호: 105-87-57696</p>
            <p>
              이용문의:{" "}
              <a href="mailto:semomun@specupad.com" className="underline">
                semomun@specupad.com
              </a>
            </p>
            <p>
              고객센터:{" "}
              <a href="https://pf.kakao.com/_JAxdGb" className="underline">
                카카오_채널 '세모문'
              </a>
            </p>
          </div>
          <div className="flex mt-5 space-x-8 items-center justify-center">
            <p>Copyright © 스팩업애드. All Rights Reserved</p>
          </div>
        </div>
      </div>
    </>
  );
};
